<template>
    <div class="app-main">
    <CommonWorldHeader></CommonWorldHeader>
    <slot/>
    </div>
</template>
<script setup>
</script>
<style lang="scss" scoped>
.app-main{
    min-width: 375px;
    max-width: 1024px;
    margin: 0 auto;
    // padding: 0 20px 20px 20px;
    padding: max(env(safe-area-inset-top, 20px),20px) max(env(safe-area-inset-right,20px),20px) max(env(safe-area-inset-bottom, 20px),20px) max(env(safe-area-inset-left,20px),20px);
}
</style>